import { render, staticRenderFns } from "./PaymentsLeadsGrid.vue?vue&type=template&id=3fc5e317&scoped=true"
import script from "./PaymentsLeadsGrid.vue?vue&type=script&lang=js"
export * from "./PaymentsLeadsGrid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc5e317",
  null
  
)

export default component.exports