var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filter,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refClientsList'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refClientsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.visibleFields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(result)",fn:function(data){return [_c('span',{class:data.item.result == 'Approved' ? 'text-success' : 'text-danger'},[_vm._v(_vm._s(data.item.result))])]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(_vm._s(_vm._f("myGlobal")(data.item.created_at))+" "+_vm._s(_vm._f("myHourTime")(data.item.created_at)))]}},{key:"cell(lead_name)",fn:function(data){return [(_vm.program == 1)?_c('router-link',{class:_vm.textLink,attrs:{"to":{
              name: 'bussiness-lead-show',
              params: { id: data.item.lead_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 4)?_c('router-link',{attrs:{"to":{
              name: 'debtsolution-lead-show',
              params: { id: data.item.lead_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 2)?_c('router-link',{attrs:{"to":{
              name: 'boostcredit-lead-show',
              params: { id: data.item.lead_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 3)?_c('router-link',{attrs:{"to":{
              name: 'creditexperts-lead-show',
              params: { id: data.item.lead_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 5)?_c('router-link',{attrs:{"to":{
              name: 'taxresearch-dashboard',
              params: { idClient: data.item.account_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 6)?_c('router-link',{attrs:{"to":{
              name: 'claimdepartment-dashboard',
              params: { idClient: data.item.account_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 7)?_c('router-link',{attrs:{"to":{
              name: 'specialists-dashboard',
              params: { idClient: data.item.account_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e(),(_vm.program == 8)?_c('router-link',{attrs:{"to":{
              name: 'bookeeping-dashboard',
              params: { idClient: data.item.account_id },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_vm._e()]}},{key:"cell(amount)",fn:function(data){return [_vm._v("$ "+_vm._s(_vm._f("currencyZero")(data.item.amount)))]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }