<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refClientsList'].refresh()"
    >
      <template #table>
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="refClientsList"
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(result)="data"
            ><span
              :class="
                data.item.result == 'Approved' ? 'text-success' : 'text-danger'
              "
              >{{ data.item.result }}</span
            ></template
          >
          <template #cell(created_at)="data"
            >{{ data.item.created_at | myGlobal }}
            {{ data.item.created_at | myHourTime }}</template
          >
          <template v-slot:cell(lead_name)="data">
            <router-link
              v-if="program == 1"
              :class="textLink"
              :to="{
                name: 'bussiness-lead-show',
                params: { id: data.item.lead_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 4"
              :to="{
                name: 'debtsolution-lead-show',
                params: { id: data.item.lead_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 2"
              :to="{
                name: 'boostcredit-lead-show',
                params: { id: data.item.lead_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 3"
              :to="{
                name: 'creditexperts-lead-show',
                params: { id: data.item.lead_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 5"
              :to="{
                name: 'taxresearch-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 6"
              :to="{
                name: 'claimdepartment-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 7"
              :to="{
                name: 'specialists-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <router-link
              v-if="program == 8"
              :to="{
                name: 'bookeeping-dashboard',
                params: { idClient: data.item.account_id },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
          </template>
          <template v-slot:cell(amount)="data">$ {{
            data.item.amount|currencyZero
          }}</template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import fields from "@/views/commons/components/payments/views/data/fields.leads.data";
import PaymentsService from "@/views/commons/components/payments/services/Payments.service";
import { mapGetters } from "vuex";
import filtersData from "@/views/commons/components/payments/views/data/filters.leads.data";

export default {
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: "created_at",
      sortDesc: true,
      fields: fields,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      filter: filtersData,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    program() {
      return this.convertoModuleToProgramC(this.moduleId);
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    myProvider: async function (ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          type: null,
          user: null,
          result: null,
          programId: this.program,
          order: ctx.sortBy == "" ? "created_at" : ctx.sortBy,
          orderby: ctx.sortDesc == 1 ? "desc" : "asc",
        };

        const res = await PaymentsService.getAllPaymentsLeads(obj);
        this.startPage = res.data.from;
        this.paginate.currentPage = res.data.current_page;
        this.paginate.perPage = res.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = res.data.last_page;
        this.totalData = res.data.total;
        this.totalRows = res.data.total;
        this.toPage = res.data.to;
        return res.data.data || [];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
