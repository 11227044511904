export default [
    {
        key:"lead_name",
        label:"Name",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"type_transaction",
        label:"Type",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"amount",
        label:"Amount",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"transaction_id",
        label:"Transaction",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"result",
        label:"Result",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"card_number",
        label:"Credit Card",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"program",
        label:"Program",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"user_name",
        label:"User",
        visible:true,
        tdClass:'tdClass',
    },
    {
        key:"created_at",
        label:"Creation Date",
        visible:true,
        tdClass:'tdClass',
        sortable: true,
    }

]